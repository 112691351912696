import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div>
      <h1>Ta strona nie istnieje.</h1>
      <Link to="/">wróć do strony głównej!</Link>
    </div>
  </Layout>
)

export default NotFoundPage
